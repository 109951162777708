/* Website template by freewebsitetemplates.com */
/*------------------------- Layout styles ------------------------*/ 
body{
	margin:0;
	background:url(../assets/images/bg-body.jpg);
}
.page{
	background:url(../assets/images/bg-header.jpg) no-repeat center top;
	width: 97%;
}

/*------------------------- Font ------------------------*/ 
@font-face {
    font-family: 'RokkittRegular';
    src: url('rokkitt-regular-webfont.eot');
    src: url('rokkitt-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('rokkitt-regular-webfont.woff') format('woff'),
         url('rokkitt-regular-webfont.ttf') format('truetype'),
         url('rokkitt-regular-webfont.svg#RokkittRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*------------------------- Header ------------------------*/ 
.header{
	background:url(../assets/images/bg-navigation.jpg) no-repeat center 110px;
	width:97%;
	height: 128px;
	margin:0 auto;
	padding:36px 10px 0;
}
.header a#logo{
	display:inline-flex;
	float:left;
	outline:none;
	cursor: pointer;
	color: #0080FF;
	font-size: 50px;
	margin-left: 70px;
}

.header a#logo img{
	height: 50px;
	width: 50px;
	margin-right: 5px;
}

.header ul{
	margin: 0px 50px 0 0;
	list-style:none;
	padding:13px 0 0;
	float:right;
}
.header ul li{
	float:left;
	margin:0 0 0 50px;
}
.header ul li.selected a,.header ul li a:hover{
	color:#0080FF;
}
.header ul li a{
	font-size:20px;
	text-decoration:none;
	color:#5e5e5e;
	font-family: 'RokkittRegular';
	outline:none;
	cursor: pointer;
}

@media screen and (max-width:500px) {
	.header ul li a{
		font-size:16px;
	}
}

/*------------------------- Body ------------------------*/ 
.body{
	width:97%;
	margin: auto;
	padding:0 10px;
	overflow-y: hidden;
}

.body.home{
	padding: 0 30px
}

.body .contact-form button.submit-button{
	background: #0080FF;
	border:0;
	border-radius: 10px;
    height:32px;
    width:100px;
	font-family: 'RokkittRegular';
	color:#fff;
	font-size:20px;
	margin:25px 0 25px 37%;
	cursor:pointer;
	outline:none;
}

.body .contact-form{
	width: 30%;
	margin: 50px 0 50px 33%;
	border: #0080FF solid 2px;
	border-radius: 10px;
}

.body .contact-form h1{
	margin-left: 25px;
}

.body .contact-form div{
	padding: 0 25px;
	margin-bottom: 10px;
}

.body .contact-form input#text-input{
	width: 100%;
	height: 25px;
	border-radius: 10px;
}

.body .contact-form textarea#textarea-input{
	width: 100%;
	height: 100px;
	border-width: 2px;
	border-radius: 10px;
}

@media screen and (max-width:500px) {
	.body .contact-form{
		width: 97%;
		margin-left: 2%;
	}
}

.body .description-container{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 500px) {
	.body .description-container{
		flex-direction: column;
		width: 90%;
	}
}

.body .description-container div#description-contact{
	min-width: 300px;
	/* height: 200px; */
	border: #0080FF solid 2px;
	border-radius: 10px;
	background-color: #fff;
}

.body .description-container div#description-contact button.contact-button{
	background: #0080FF;
	border:0;
	border-radius: 10px;
    height:32px;
    width:100px;
	font-family: 'RokkittRegular';
	color:#fff;
	font-size:20px;
	margin:0 0 25px 100px;
	cursor:pointer;
	outline:none;
}

.body .description-container div#description-contact h1{
	margin-left: 80px;
	font-family: 'RokkittRegular';
	color:#5e5e5e;
	white-space: nowrap;
}

.body .description-container div#description-contact h2{
	color: #0080FF;
	margin-left: 80px;
	white-space: nowrap;
}

.body div#featured{
	/* background:url(../assets/images/house.jpg) no-repeat right top; */
	display: inline-block;
	/* height:448px; */
	padding:0 20px 17px 20px;
	max-width: 70%;
}

.body div#featured h3{
	color:#0080FF;
	font-size:30px;
	line-height: 30px;
	font-family: 'RokkittRegular';
	margin:0 0 30px;
	padding:100px 0 0;
	font-weight:normal;
}
.body div#featured p{
	color:#5d5d5d;
	font-family: 'RokkittRegular';
	font-size:22px;
	line-height:25px;
	text-shadow: 0 1px 0 #fcfcfc;
	text-align:justify;
	margin:0;
}
.body div#featured input{
	background: #0080FF;
	border:0;
	border-radius: 10px;
    height:48px;
    width:216px;
	font-family: 'RokkittRegular';
	color:#fff;
	font-size:30px;
	margin-top: 28px;
	cursor:pointer;
	outline:none;
}

@media screen and (max-width:500px) {
	.body div#featured{
		max-width: 90%;
	}

	.body div#featured input{
		margin-left: 16%;
	}
}


.body h3{
	font-family: 'RokkittRegular';
	font-size:16px;
	font-weight:bold;
	color:#636363;
	margin:15px 0 0;
	line-height:21px;
}
.body p{
	font-family: 'RokkittRegular';
	font-size:16px;
	text-align:justify;
	color:#636363;
	margin:0 0 20px;
	line-height:21px;
}
.body p a{
	outline:none;
	color:#636363;
}
.body ul{
	list-style:none;
    margin-right:0;
    padding:0;
	overflow:hidden;
}
.body ul li:first-child{
	background:none;
}
.body ul li{
	overflow:hidden;
	background:url(../assets/images/separator.jpg) no-repeat center top;
	padding-top:30px;
	width: 95%;
	display: flex;
}
.body ul li div.featured{
	width:281px;
	/* height:292px; */
	text-align: center;
	margin:0;
}

.body ul li div.featured img{
	height: 250px;
	width: 300px;
	margin:10px 0 0 20%;
	border:0;
}

.body ul li div.featured ul{
	overflow:hidden;
	margin:2px 0 0 10px;
	
}
.body ul li div.featured ul li:first-child{
	margin:0;
}
.body ul li div.featured ul li{
	float:left;
	margin:0 0 0 18px;
	background:none;
	padding:0;
}
.body ul li div.featured ul li a{
	padding:0;
}
.body ul li div.featured ul li a img{
	margin:0;
}
.body ul li div.featured ul li a img:hover{
	filter:alpha(opacity=80);
	opacity:0.8;
}
.body ul li div.featured a{
	clear:both;
	display:block;
	font-size:13px;
	font-family: 'RokkittRegular';
	text-decoration:none;
	color:#8a8889;
	font-weight:bold;
	padding:5px 0 0;
	width:auto;
	outline:none;
}
.body ul li div{
	float:left;
	margin:0 0 0 30px;
}
.body ul li div h3{
	font-size:27px;
	font-weight:bold;
	font-family: 'RokkittRegular';
	color:#0080FF;
	line-height:22px;
	padding-left: 10%;
}
.body ul li div p{
	line-height:25px;
	font-family: 'RokkittRegular';
	font-size:22px;
	color:#5D5D5D;
	padding:0 0 40px 10%;
	margin:0;
}

@media screen and (max-width:500px) {
	.body ul li{
		flex-direction: column;
	}
	.body ul li div.featured img{
		margin-left: 20%;
	}

	.body ul li div p{
		padding-left: 0;
	}
	
}

.body ul.paging{
	margin:15px 0 10px 210px;
}
.body ul.paging li{
	float:left;
	padding:0 5px;
	background:none;
}
.body ul.paging li a{
	text-decoration:none;
	color:#ff7e0d;
	font-family: arial;
	font-size:12px;
}
.body ul.paging li a:hover{
	color:#5D5D5D;
}

/*------------------------- Footer ------------------------*/ 
.footer{
	display: inline-flex;
	/* flex-wrap: wrap; */
	width:97%;
	background:url(../assets/images/bg-footer.jpg) no-repeat center top;
	padding:65px 10px 40px;
	margin:0 auto;
	overflow:hidden;
	align-items: center;
	justify-content: center;
}

.footer .information-container{
	margin: 0 6%;
}

.footer .information-container .info h3.phone{
	margin-left: 20px;
	margin-bottom: -15px;
}

.footer .map-container{
	width: 66%;
}

@media screen and (max-width: 500px) {
	.footer {
		flex-direction: column;
	}
	.footer .map-container{
		width: 100%;
	}
}
